import detectFormAttribute from "@/javascript/components/detect_form_attribute"

// This add supports for the HTML5 form attribute for browsers that don't support it (IE 11)
export default function formData (form) {
  if(detectFormAttribute()) {
    return new FormData(form)
  } else {
    const formData = new FormData(form)
    // Sweep up the orphaned HTML5 attributes
    document.querySelectorAll(`[form='${form.id}']`).forEach((elem) => {
      formData.append(elem.name, elem.value)
    })

    return formData
  }
}
