import Loader from "@/javascript/components/loader"

class JobCategoriesControls {
  constructor(container) {
    this.container = container
    this.listenJobCategories()
  }

  listenJobCategories() {
    document.addEventListener('job_categories:refresh', (e) => {
      if(e.detail.categories.length > 0) {
        this.container.classList.add('open')
      } else {
      this.container.classList.remove('open')
      }
    })
  }
}

Loader.functional((content, resolve) => {
  const jobCategoryControlsContainer = content.querySelector('.js-job-categories-controls')

  if(!jobCategoryControlsContainer) {
    resolve()
    return
  }

  if(!jobCategoryControlsContainer.control) {
    jobCategoryControlsContainer.control = new JobCategoriesControls(jobCategoryControlsContainer)
  }

  resolve()
})
