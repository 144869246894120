// Taken from https://github.com/Modernizr/Modernizr/pull/640/files

export default function detectFormAttribute () {
  let form = document.createElement("form")
  let input = document.createElement("input")
  let div = document.createElement("div")
  let id = "formtest"+(new Date().getTime())
  let attr
  let bool = false

  form.id = id;

  //IE6/7 confuses the form idl attribute and the form content attribute
  if(document.createAttribute){
    attr = document.createAttribute("form")
    attr.nodeValue = id
    input.setAttributeNode(attr)
    div.appendChild(form)
    div.appendChild(input)

    document.documentElement.appendChild(div)

    bool = form.elements.length === 1 && input.form == form

    div.parentNode.removeChild(div)
  }

  return bool;
}
