import Loader from "@/javascript/components/loader"

class JobNotificationsControls {
  constructor (container) {
    this.selectedNotifications = new Set()
    this.container = container
    this.storeCategoriesNotifications()
    this.bindCheckboxes()
    this.fillSelectedNotifications()
    this.listenJobCategories()
  }

  storeCategoriesNotifications () {
    this.categoriesNotifications = this.container.querySelectorAll(".options .option")
  }

  bindCheckboxes () {
    this.categoriesNotifications.forEach((notification) => {
      notification.querySelector('input[type="checkbox"]').addEventListener('change', (e) => {
        const target = e.target
        if(target.checked) {
          this._addSelectedNotification(target.value)
        } else {
          this._removeSelectedNotification(target.value)
        }
      })
    })
  }

  fillSelectedNotifications() {
    this.container.querySelectorAll('input[type="checkbox"]:checked').forEach((notification) => {
      this._addSelectedNotification(notification.value)
    })
  }

  _addSelectedNotification(categoryId) {
    this.selectedNotifications.add(categoryId)

    this._fireEvent('job_notifications:any')
  }

  _removeSelectedNotification(categoryId) {
    this.selectedNotifications.delete(categoryId)

    if(this.selectedNotifications.size === 0) {
      this._fireEvent('job_notifications:empty')
    }
  }

  listenJobCategories() {
    document.addEventListener('job_categories:refresh', (e) => {
      const categories = e.detail.categories.filter((category) => category !== "")

      this.categoriesNotifications.forEach((notification) => {
        const categoryId = notification.dataset.categoryId
        if(categories.includes(categoryId)){
          if (notification.classList.contains('hide')){
            this._addSelectedNotification(categoryId)
            notification.querySelector('input[type="checkbox"]').checked = true
            notification.classList.remove('hide')
          }
        } else {
          notification.querySelector('input[type="checkbox"]').checked = false
          notification.classList.add('hide')
          this._removeSelectedNotification(categoryId)
        }
      })

      this.bindCheckboxes()
    })
  }

  _fireEvent (name) {
    const event = new CustomEvent(name)
    document.dispatchEvent(event)
  }
}

Loader.functional((content, resolve) => {
  const jobNotificationContainer = content.querySelector('.js-job-notifications')

  if(!jobNotificationContainer) {
    resolve()
    return
  }

  if(!jobNotificationContainer.control) {
    jobNotificationContainer.control = new JobNotificationsControls(jobNotificationContainer)
  }

  resolve()
})
