import Loader from "@/javascript/components/loader"

class MaxWords {
  constructor (elem, opts = {}) {
    this.elem = elem
    this.maxWords = opts.maxWords || this.elem.dataset.maxWords
    this.wordsLeftText = opts.wordsLeftText || this.elem.dataset.maxWordsLeftText || 'words left'
    this.errorMessage = opts.errorMessage || this.elem.dataset.maxWordsErrorMessage || 'too many words'
    this._bind()
  }

  _buildCounter() {
    new Promise((resolve) => {
      this.counter = this.elem.querySelector('.errors') || document.createElement('div')
      this.counter.classList.add('max-words')
      this.elem.appendChild(this.counter)
      resolve(this.counter)
    })
  }

  _bind() {
    this._buildCounter()
    const inputElem = this.elem.querySelector('input[type="text"], textarea')
    if(!inputElem) {
      return
    }

    inputElem.addEventListener('keyup', (e) => {
      const wordsLeftCount = this.maxWords - inputElem.value.trim().split(' ').filter((string)=>string.length).length
      this.counter.innerHTML = wordsLeftCount + ' ' + this.wordsLeftText

      if(wordsLeftCount < 0) {
        this.counter.classList.add('errors')
        this.counter.innerHTML = this.errorMessage
      } else {
        this.counter.classList.remove('errors')
      }

      if(wordsLeftCount == this.maxWords) {
        this.counter.innerHTML = null
      }
    })
  }
}

Loader.functional((content, resolve) => {
  content.querySelectorAll('[data-max-words]').forEach((elem) => {
    if(elem.maxWordsEnabled) {
      return
    }
    elem.maxWordsEnabled = true
    elem.maxWords = new MaxWords(elem)
  })

  resolve()
})
