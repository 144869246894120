import { Turbo } from "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
import "./tiny-mce-html-cleaner"
import * as tinyMCE from './tinymce_editor.js'
import Loader from "@/javascript/components/loader";
import '../controllers'

Turbo.session.drive = false

if (!window._rails_loaded) {
  Rails.start()
}

import "@/javascript/components/polyfills"
import "lazysizes/plugins/unveilhooks/ls.unveilhooks"
import "lazysizes/plugins/include/ls.include"
import "lazysizes/lazysizes"
import "@/javascript/components/tools/lazy_sizes/data_module"

import "@/javascript/components/ajax_forms"
import "@/javascript/components/approximate_location"
import "@/javascript/components/callback_form"
import "@/javascript/components/clipboard"
import "@/javascript/components/cookie_banner"
import "@/javascript/components/currency_updater"
import "@/javascript/components/datepickers"
import "@/javascript/components/dismissible_cells"
import "@/javascript/components/editable"
import "@/javascript/components/equalizer"
import "@/javascript/components/external_scripts_loader"
import "@/javascript/components/faqs"
import "@/javascript/components/fix_height"
import "@/javascript/components/flash"
import "@/javascript/components/focus_target"
import "@/javascript/components/inline_editable"
import "@/javascript/components/late_prefetch"
import "@/javascript/components/load"
import "@/javascript/components/max_words"
import "@/javascript/components/modal"
import "@/javascript/components/navigation"
import "@/javascript/components/polyfills"
import "@/javascript/components/scrollto_onload"
import "@/javascript/components/selects"
import "@/javascript/components/skill_selects"
import "@/javascript/components/sort_toggles"
import "@/javascript/components/switch"
import "@/javascript/components/textarea_resize"
import "@/javascript/components/toggling"
import "@/javascript/components/touch_hover_bug"
import "@/javascript/components/cloudinary_upload"
import "@/javascript/components/job_categories/controls"
import "@/javascript/components/job_categories/onboarding_selects"
import "@/javascript/components/job_categories/select"
import "@/javascript/components/job_notifications/controls"
import "@/javascript/components/job_notifications/frequency"

// Work around for Safari bug and web-sockets
// See https://github.com/rails/rails/issues/43835
// and https://github.com/rails/rails/pull/44304

const originalWebSocketClose = WebSocket.prototype.close
WebSocket.prototype.close = function () {
  if (this.readyState != WebSocket.CONNECTING) {
    originalWebSocketClose.apply(this, arguments)
  }
}

Loader.functional((content, resolve) => {
  tinyMCE.render(content).then(resolve)
})
