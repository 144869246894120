import Selectize from "@/javascript/components/selects"
import Loader from "@/javascript/components/loader"
import { closest } from "@/javascript/components/tools/closest"
import fetch from "@/javascript/components/tools/fetch"
import formData from "@/javascript/components/tools/form_data"
import { appendHTML, replaceWith } from "@/javascript/components/tools/replace"
import reportError from "@/javascript/components/errors"
import Suggester from "@/javascript/components/selectize_suggest"

class SkillSelect {
  constructor (control) {
    this.control = control
    this.skillsWrapper = this.control.querySelector('.skills-wrapper')

    this.options = this.control.dataset
    this.jobCategoryIds = []

    if (this.options.jobCategoryIds) {
      this.jobCategoryIds = JSON.parse(this.options.jobCategoryIds)
    }
    if (this.options.suggest) {
      this.suggestions = JSON.parse(this.options.suggest)
    }

    this.requiredSkills = this.options.hasOwnProperty('requiredSkills') ? document.getElementById('required_skill_ids').value.split(' ') : []

    this._setupSkillsSelect()
    this._bindRemoval()
    this._setupSuggestions()
    this.listenJobCategories()
  }

  listenJobCategories() {
    document.addEventListener('job_categories:refresh', (e) => {
      this.jobCategoryIds = e.detail.categories
      this._select().suggester.resetSuggestions()
    })
  }

  _bindRemoval () {
    this.skillsWrapper.addEventListener('click', (e) => {
      const target = e.target
      const elem = closest(target, '.skills-list .skill .remove')
      if(!elem) {
        return
      }
      e.preventDefault()

      const skill = closest(elem, '.skill')
      this.removeSkill(skill.dataset.value)
    })
  }

  removeSkill (value) {
    this._removeRequiredSkill(value)
    this._select().selectize.removeItem(value)
    this._updateUI()
  }

  _select () {
    return this.skillsWrapper.querySelector('select.all-skills')
  }

  _setupSkillsSelect () {
    const control = this

    let create = null
    if(this.options.hasOwnProperty('allowAdd')) {
      create = function (input, callback) {
        callback()
        return control._showCreateSkillForm(input, this.$input[0])
      }
    } else {
      create = false
    }

    Selectize(this._select(), {
      closeAfterSelect: true,
      createOnBlur: false,
      search: true,
      load: this._searchSkills,
      create: create,
      onDelete: (() => false),
      render: {
        option_create: (data, escape) => {
          return '<div class="create">Create new skill <strong>' +
            escape(data.input) + '</strong>&hellip;</div>'
        },
        item: (data, escape) => {
          return '<div style="display: none" ' +
            'data-skills-category-name="' + escape(data['skills_category_name']) + '" ' +
            'data-name="' + escape(data['text']) + '">' +
            '</div>'
        },
        option: (data, escape) => {
          return '<div class="skill-option">' +
            '<span class="skill-name">' + escape(data.text) + '</span>' +
            '<span class="skills-category-name">' + escape(data.skills_category_name) + '</span>' +
            '</div>'
        }
      }
    }).then((selectize) => {
      selectize.on('item_add', (value, $item) => this._updateUI())
      this._updateUI()
    })
  }

  _updateUI () {
    // TODO: This could be a class/prototype rather than a raw object
    const skillsByCategory = {}

    this._select().selectize.getValue().forEach((value) => {
      const item = this._select().selectize.getItem(value)[0]
      const required = this._hasRequiredSkill(item.dataset.value)

      const catName = item.dataset.skillsCategoryName
      if(!skillsByCategory.hasOwnProperty(catName)) {
        skillsByCategory[catName] = []
      }

      skillsByCategory[catName].push({
        name: item.dataset.name,
        value: item.dataset.value,
        required: required
      })
    })

    const masterContainer = document.createElement('div')

    Object.entries(skillsByCategory).forEach((entry) => {
      const [categoryName, skills] = entry
      const categoryContainer = document.createElement('div')
      categoryContainer.classList.add('skills-category')

      const categoryNameDiv = document.createElement('div')
      categoryNameDiv.classList.add('name')
      categoryNameDiv.innerHTML = categoryName
      categoryContainer.appendChild(categoryNameDiv)

      const skillsDiv = document.createElement('div')

      skills.forEach((skill) => {
        skillsDiv.appendChild(this._createSkillDiv(skill))
      })

      categoryContainer.appendChild(skillsDiv)
      masterContainer.appendChild(categoryContainer)
    })

    const skillsList = this.skillsWrapper.querySelector('.skills-list')
    skillsList.innerHTML = ""
    skillsList.appendChild(masterContainer)
  }

  _createSkillDiv (skill) {
    const skillDiv = document.createElement('div')
    skillDiv.classList.add('skill')
    skillDiv.dataset.value = skill.value

    if(this.options.hasOwnProperty('requiredSkills')) {
      skillDiv.appendChild(this._createSkillCheckboxSpan(skill))
    }

    const skillText = document.createElement('span')
    skillText.innerHTML = skill.name
    skillDiv.appendChild(skillText)

    const removeSpan = document.createElement('span')
    removeSpan.classList.add('remove')
    skillDiv.appendChild(removeSpan)

    return skillDiv
  }

  _createSkillCheckboxSpan (skill) {
    const checkboxSpan = document.createElement('span')
    checkboxSpan.classList.add('checkbox')

    const id = `required_skill_${skill.value}`

    const checkbox = document.createElement('input')
    checkbox.type = 'checkbox'
    checkbox.name = "required_skill_ids"
    checkbox.value = skill.value
    checkbox.id = id
    checkbox.checked = skill.required
    checkboxSpan.appendChild(checkbox)

    checkbox.addEventListener('change', (e) => {
      e.preventDefault()
      const target = e.target
      const value = target.value
      if (target.checked) {
        this._addRequiredSkill(value)
      } else {
        this._removeRequiredSkill(value)
      }
    })

    const label = document.createElement('label')
    label.htmlFor = id
    checkboxSpan.appendChild(label)

    return checkboxSpan
  }

  _searchSkills (query, callback) {
    if(!query.length){
      return
    }

    const url = new URL('/api/v1/skills/search', window.location.origin)
    url.searchParams.set('q', query)

    return fetch(url)
      .then((response) => response.json())
      .then((data) => callback (data.skills))
      .catch((error) => {
        reportError(error)
        callback()
      })
  }

  _addCreateSkillFormTag () {
    const id = this._skillFormId()
    if(document.getElementById(id)) {
      return
    }

    const form = document.createElement('form')
    form.setAttribute('id', id)
    document.getElementsByTagName('body')[0].appendChild(form)
  }

  _createSkillFormContainer () {
    return this.skillsWrapper.querySelector('.new-skill-form-container')
  }

  _showCreateSkillForm (input, select) {
    if(this.creating) {
      return
    }
    this.creating = true
    const url = new URL('/api/v1/skill_selects/new', window.location.origin)
    url.searchParams.set('skill[name]', input)
    url.searchParams.set('skill[form_id]', this._skillFormId())
    this._addCreateSkillFormTag()

    fetch(url)
      .then((response) => {
        if(!response.ok) {
          throw Error(response.statusText)
        }
        return response.text()
      })
      .then((html) => {
        this.skillsWrapper.querySelector('.selectize-control').style.display = 'none'

        appendHTML(this._createSkillFormContainer(), html, (newContent) => {
          this._bindCreateSkillForm()
        })
      })
      .catch((error) => reportError(error))
  }

  _bindCreateSkillForm () {
    this.skillsWrapper.querySelector('.submit-new-skill').addEventListener('click', (e) => {
      e.preventDefault()
      this._submitCreateSkillForm()
    })

    this.skillsWrapper.querySelector('.cancel-new-skill').addEventListener('click', (e) => {
      e.preventDefault()
      this._removeCreateSkillForm()
    })
  }

  _submitCreateSkillForm () {
    fetch('/api/v1/skill_selects', {method: 'POST', body: this._skillFormData()})
      .then((response) => {
        if(response.status === 422) {
          response.text().then((html) => {
            replaceWith(this._createSkillFormContainer().firstChild, html, () => {
              this._bindCreateSkillForm()
            })
          })
        } else if(response.ok) {
          response.json().then((result) => this._addNewSkill(result))
        } else {
          throw Error(response.statusText)
        }
      })
      .catch((error) => reportError(error))
  }

  _skillFormData () {
    return formData(document.getElementById(this._skillFormId()))
  }

  _skillFormId () {
    return "create_skill_form"
  }

  _addNewSkill (newSkill) {
    this._select().selectize.addOption({
      skills_category_name: newSkill.skills_category_name,
      text: newSkill.name,
      value: newSkill.id
    })
    this._select().selectize.addItem(newSkill.id)

    this._removeCreateSkillForm()
  }

  _removeCreateSkillForm () {
    this.skillsWrapper.querySelector('.selectize-control').style.display = 'block'
    this._createSkillFormContainer().innerHTML = ''
    this.creating = false
  }

  _setupSuggestions () {
    if(!this.suggestions) {
      return
    }

    this._select().suggester ||= new Suggester(this._select().selectize, {
      suggestionUrl: this.suggestions.path,
      text: this.suggestions.text,
      sources: this.suggestions.sources,
      getData: () => {
        return {
          values: this._select().selectize.getValue().join(','),
          job_category_ids: this._jobCategoryIds().join(',')
        }
      }
    })
  }

  _jobCategoryIds () {
    return this.jobCategoryIds
  }

  _addRequiredSkill (value) {
    this.requiredSkills.push(value)
  }

  _removeRequiredSkill (value) {
    const index = this.requiredSkills.indexOf(value)
    if(index > -1) {
      this.requiredSkills.splice(index, 1)
    }
  }

  _hasRequiredSkill (value) {
    return this.requiredSkills.includes(value)
  }
}

Loader.functional((content, resolve) => {
  content.querySelectorAll('[data-skills-select]').forEach((control) => {
    if(!control.skillSelect) {
      control.skillSelect = new SkillSelect(control)
    }
  })

  resolve()
})
