import Loader from "@/javascript/components/loader"

class JobNotificationsFrequency {
  constructor(container) {
    this.container = container
    this.listenJobNotifications()
    this.listenJobCategories()
  }

  listenJobNotifications() {
    document.addEventListener('job_notifications:empty', () => {
      this.container.classList.add('hidden')
    })
    document.addEventListener('job_notifications:any', () => {
      this.container.classList.remove('hidden')
    })
  }

  listenJobCategories() {
    document.addEventListener('job_categories:refresh', (e) => {
      const categories = e.detail.categories.filter((category) => category !== "")

      if(categories.length > 0) {
        this.container.classList.remove('hidden')
      } else {
        this.container.classList.add('hidden')
      }
    })
  }
}

Loader.functional((content, resolve) => {
  const notificationFrequencyContainer = content.querySelector('.js-job-notification-frequency')

  if(!notificationFrequencyContainer) {
    resolve()
    return
  }

  if(!notificationFrequencyContainer.control) {
    notificationFrequencyContainer.control = new JobNotificationsFrequency(notificationFrequencyContainer)
  }

  resolve()
})
