import Loader from "@/javascript/components/loader"
import { fadeOut, fadeIn } from "@/javascript/components/animations/fade"
import { slideTransition } from "@/javascript/components/animations/slide"
import { matches } from "@/javascript/components/tools/closest"
import CSRFFetch from "@/javascript/components/tools/fetch"

const getChild = (elem, selector) => {
  return [...elem.children].find((elem) => matches(elem, selector))
}

class InlineEditable {
  constructor (element) {
    this.element = element
    this.setMode('show')
    this.element.classList.add(`${this.mode}-state`)
    this.container = this.element.querySelector('.inline-container')
    this.showView = getChild(this.container, '.inline-show')
    this.editPath = this.element.dataset.editPath
    this._bind()
  }

  _bind () {
    this.element.addEventListener('formSubmitted', (e) => {
      if(!e.detail.success) {
        return
      }

      this.showView.remove()
      this.editView.classList.remove('inline-edit')
      this.editView.classList.add('inline-show')
      this.showView = this.editView
      this.editView = null
      this.setMode('show')
      this._bindButtons()
    })
    this._bindButtons()
  }

  _bindButtons () {
    this.element.querySelectorAll('.edit-button').forEach((link) => {
      if(link.inlineEditableBound) {
        return
      }

      link.addEventListener('click', (e) => {
        e.preventDefault()
        this.edit()
      })
      link.inlineEditableBound = true
    })

    this.element.querySelectorAll('.cancel-button').forEach((link) => {
      if(link.inlineEditableBound) {
        return
      }

      link.addEventListener('click', (e) => {
        e.preventDefault()
        this.cancel()
      })
      link.inlineEditableBound = true
    })
  }

  setMode (mode) {
    if(mode === this.mode) {
      return
    }

    this.element.classList.remove(`${this.mode}-state`)
    this.mode = mode
    this.element.classList.add(`${this.mode}-state`)
  }

  edit () {
    if(this.mode === 'edit') {
      return
    }

    this.setMode('edit')
    slideTransition(this.container, 300, () => {
      return Promise.all([
        this.loadEditForm(),
        this.showLoader()
      ])
      .then(() => {
        if(this.mode === 'edit') {
          this.showView.style.setProperty('display', 'none')
        } else {
          this.editView = null
          this.editView.remove()
        }

        this.removeLoader()
        return this.editView
      })
    })
  }

  cancel () {
    if(this.mode !== 'edit') {
      return
    }

    this.setMode('show')
    slideTransition(this.container, 300, () => {
      return this.showLoader().then(() => {
        if(this.mode === 'show') {
          this.editView.remove()
          this.editView = null
          this.showView.style.removeProperty('display')
        }
        this.removeLoader()
        return this.showView
      })
    })
  }

  loadEditForm () {
    if(this.editView) {
      return Promise.resolve(this.editView)
    }

    return CSRFFetch(this.editPath)
      .then((response) => response.text())
      .then((form) => this.buildEditView(form))
      .then((editView) => new Loader(editView).all())
      .then(() => this._bindButtons())
  }

  showLoader () {
    if(this.loader) {
      return Promise.resolve(this.loader)
    }

    this.loader = document.createElement('div')
    this.loader.classList.add('loader')

    this.loader.style.setProperty('opacity', 0)
    this.container.appendChild(this.loader)
    return fadeIn(this.loader, 300)
  }

  removeLoader () {
    let loader = this.loader
    if(!loader) {
      return
    }

    this.loader = null
    fadeOut(loader, 300).then(() => loader.remove())
  }

  buildEditView (form) {
    if(this.editView) {
      this.editView.remove()
    }
    this.editView = document.createElement('div')
    this.editView.classList.add('inline-edit')
    this.editView.innerHTML = form
    this.container.appendChild(this.editView)
    return this.editView
  }
}

Loader.functional((container, resolve) => {
  container.querySelectorAll('[data-inline-edit]').forEach((element) => {
    element.editor = new InlineEditable(element)
  })
  resolve()
})
