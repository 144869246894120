import Loader from "@/javascript/components/loader"

// Fixes the height of an element. This is useful if the element wraps a remotely updating form
// which can change the height during the rendering process
Loader.visual((content, resolve) => {
  content.querySelectorAll('[data-fixed-height]').forEach((elem) => {
    elem.style.setProperty('height', elem.offsetHeight + 'px')
  })
  resolve()
})

// Same as above, but this fixes the min-height of the element, which is useful if the form can
// expand to incorporate errors
Loader.visual((content, resolve) => {
  content.querySelectorAll('[data-fixed-min-height]').forEach((elem) => {
    elem.style.setProperty('min-height', elem.offsetHeight + 'px')
  })
  resolve()
})

