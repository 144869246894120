import { closest } from "@/javascript/components/tools/closest"

document.addEventListener('click', (e) => {
  const target = e.target
  const elm = closest(target, 'a[data-focus-target][href]')
  if(!elm) {
    return
  }

  const focusTargetElement = document.querySelector(elm.dataset.focusTarget)

  if(!focusTargetElement) {
    return
  }

  e.preventDefault()
  focusTargetElement.focus()
})
