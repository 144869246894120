import Pikamonth from "pikamonth/pikamonth"
import Pikaday from "pikaday/pikaday"
import { closest, matches } from "@/javascript/components/tools/closest"
import Loader from "./loader"
import moment from 'moment'

Loader.functional((container, resolve) => {
  container.querySelectorAll('input.pickaday').forEach((element) => {
    if(matches(element, '.month-only')) {
      element.pikaday = new Pikamonth({
        field: element,
        format: 'MMMM YYYY',
        // Pikadate can no longer detect that we moment is available, so we nede to parse it
        // ourselves, in our own code, where we can correctly import moment
        parse(value, format) {
          return moment(value, format).toDate()
        },
        toString(date, format) {
          const month = date.toLocaleString('default', { month: 'long' })
          let year = date.getFullYear()

          return `${month} ${year}`
        }
      })
    } else {
      let args = {
        field: element,
        format: 'DD/MM/YYYY',
        // Pikadate can no longer detect that we moment is available, so we nede to parse it
        // ourselves, in our own code, where we can correctly import moment
        parse(value, format) {
          return moment(value, format).toDate()
        },
        toString(date, format) {
          let day = date.getDate()
          day = day.toString().padStart(2, '0')
          let month = date.getMonth() + 1
          month = month.toString().padStart(2, '0')
          let year = date.getFullYear()

          return `${day}/${month}/${year}`
        }
      };
      if(element.dataset.defaultDate) {
        args.defaultDate = new Date(element.dataset.defaultDate)
      }

      element.pikaday = new Pikaday(args);

      if(element.dataset.minDate) {
        element.pikaday.setMinDate(new Date(element.dataset.minDate))
      }
      if(element.dataset.maxDate) {
        element.pikaday.setMaxDate(new Date(element.dataset.maxDate))
      }
    }
  })
  resolve()
})

document.addEventListener('click', (e) => {
  const target = e.target
  if(!matches(target, '.input-wrapper.pickaday .clear')) {
    return
  }
  const input = closest(target, '.input-wrapper').querySelector('input.pickaday')
  input.pikaday.setDate(null)
})

document.addEventListener('change', (e) => {
  const target = e.target
  if(!matches(target, 'input.pickaday')) {
    return
  }

  const clearButton = closest(target, '.input-wrapper').querySelector('.clear')
  if(target.value) {
    clearButton.classList.remove('hidden')
  } else {
    clearButton.classList.add('hidden')
  }
})
