import Selectize from "@/javascript/components/selects"
import resized from "@/javascript/components/events/resized"
import CSRFFetch from "@/javascript/components/tools/fetch"
import reportError from "@/javascript/components/errors"
import Loader from "@/javascript/components/loader"
import "@/javascript/components/selectize/no_results"

class ApproximateLocation {
  constructor (element, opts) {
    this.throttle = opts.throttle
    this.element = element
    this._setupCountrySelect()
    this._storeCommonElements()
    this.ready = this._setupLocationSelect().then(() => this._bindElements())
  }

  _storeCommonElements() {
    this.postcodeLabel = this.element.querySelector('label.postcode')
    this.cityLabel = this.element.querySelector('label.city')
    this.approxLocation = this.element.querySelector('.approx-location-name')
    this.locationField = this.element.querySelector('.location-field')
    this.locationName = this.element.querySelector('.selected-location .name')
    this.editLink = this.element.querySelector('.selected-location .name')
    this.clearLink = this.element.querySelector('.selected-location .clear')
    this.locationControls = this.element.querySelector('.location-controls')
    this.locationResults = this.element.querySelector('.location-results')
  }
  _searchLocation (query, callback) {
    const url = new URL('/approximate_location/suggest', window.location.origin)
    url.searchParams.set('q', query)
    url.searchParams.set('type', (this._isPostcodeCountry() ? 'postcode' : 'place'))
    url.searchParams.set('country_code', this.countryCodeSelectize.getValue())

    CSRFFetch(url)
      .then((response) => response.json())
      .then((data) => callback(data.suggestions))
      .catch((error) => {
        reportError(error)
        callback()
      })
  }

  _setupCountrySelect() {
    const countrySelect = this.element.querySelector('select.country-select')

    Selectize(countrySelect, {search: true})
      .then((selectize) => {
        this.countryCodeSelectize = selectize

        selectize.on('change', (newValue) => {
          this._removeLocation()
          if(newValue) {
            this._showEditMode()
          }
          resized(this.element)
        })
      })
  }

  _setupLocationSelect() {
    const locationSelect = this.element.querySelector('select.location-select')

    return Selectize(locationSelect, {
      search: true,
      sort: 'text',
      loadThrottle: this.throttle,
      load: (query, callback) => this._searchLocation(query, callback),
      plugins: ['no_results'],
      score: function(search) {
        const score = this.getScoreFunction(search)
        return function(item) {
          const item_without_spaces = Object.assign({}, item)
          item_without_spaces.text = item_without_spaces.text.replace(/\s/g, '')
          return score(item_without_spaces)
        }
      }
     }).then((selectize) => {
        this.locationSelectize = selectize

        selectize.on('change', (newValue) => {
          this.valueChanged = true
          if(newValue) {
            this._selectLocation()
          }
        })

        selectize.on('blur', () => this._showLocation())
    })
  }

  _isPostcodeCountry() {
    return !!this.countryCodeSelectize
      .revertSettings
      .$children
      .filter(`[value='${this.countryCodeSelectize.getValue()}']`)[0]
      .dataset
      .postcode
    }

  _bindElements() {
    this.editLink.addEventListener('click', (e) => {
      e.preventDefault()
      this._showEditMode()
      this.locationSelectize.focus()
    })

    this.clearLink.addEventListener('click', (e) => {
      e.preventDefault()
      this._removeLocation()
      this._showEditMode()
    })
  }

  _showEditMode() {
    this.locationSelectize.clearOptions()
    this.locationSelectize.clear()
    this.locationSelectize.enable()
    this.valueChanged = false
    this.locationResults.classList.add('hidden')
    this.locationControls.classList.remove('hidden')

    if (this._isPostcodeCountry()) {
      this.cityLabel.classList.add('hidden')
      this.postcodeLabel.classList.remove('hidden')
    } else {
      this.cityLabel.classList.remove('hidden')
      this.postcodeLabel.classList.add('hidden')
    }

    resized(this.element)
  }

  _removeLocation() {
    this.locationSelectize.clear()
    this.locationSelectize.clearOptions()
    this.locationSelectize.disable()
    this.locationField.value = ""
    this.approxLocation.innerHTML = ""
    this.locationName.innerHTML = ""

    this.locationResults.classList.add('hidden')
    this.cityLabel.classList.add('hidden')
    this.postcodeLabel.classList.remove('hidden')
    this.locationControls.classList.remove('hidden')

    const event = new CustomEvent('locationRemoved', {bubbles: true})
    this.element.dispatchEvent(event)
  }

  _selectLocation() {
    const parsed = JSON.parse(this.locationSelectize.getValue())
    this.locationField.value = this.locationSelectize.getValue()

    this.approxLocation.innerHTML = parsed.approxLocation
    this.locationName.innerHTML = parsed.name
    this.valueChanged = false

    this._showLocation()
    const event = new CustomEvent('locationSelected', {bubbles: true})
    this.element.dispatchEvent(event)
  }

  _showLocation() {
    if (!this._hasSelectedLocation()) {
      return
    }

    this.locationResults.classList.remove('hidden')
    this.locationControls.classList.add('hidden')

    resized(this.element)
  }

  _hasSelectedLocation() {
    return this.locationField.value && !this.valueChanged
  }
}

Loader.functional((container, resolve) => {
  let promises = []
  container.querySelectorAll('.approximate-location').forEach(elem => {
    const opts = {
      throttle: elem.dataset.throttle
    }
    elem.approxLocator ||= new ApproximateLocation(elem, opts)
    promises.push(elem.approxLocator.ready)
  })
  Promise.all(promises).then(() => resolve())
})
