import CSRFFetch from "@/javascript/components/tools/fetch"
import Loader from "@/javascript/components/loader"

class RemoteSwitch {
  constructor (element) {
    this.element = element
    this.label = this.element.querySelector('label')
    this.input = this.element.querySelector('input[type="checkbox"]')
    this._bind()
  }

  _bind () {
    this.label.addEventListener('click', (e) => {
      e.preventDefault()
      if(this.fetching) {
        return
      }
      this._fetch()
    })
  }

  _fetch () {
    this.fetching = true
    const data = {}
    const newValue = data[this.element.dataset.remoteParam || 'state'] = !this.input.checked

    const headers = {}
    headers['Content-Type'] = 'application/json'

    CSRFFetch(this.element.dataset.remotePath, {
      method: 'post',
      body: JSON.stringify(data),
      headers: headers
    }).then((response) => {
      this.fetching = false
      if(!response.ok) {
        return
      }

      this.input.checked = newValue

      if(document.createEvent) {
        const evt = document.createEvent("HTMLEvents")
        evt.initEvent("change", false, true)
        this.input.dispatchEvent(evt)
      } else {
        this.input.fireEvent("onchange")
      }
    })
  }
}

Loader.functional((container, resolve) => {
  container.querySelectorAll('.ui-switch[data-remote-path]').forEach((uiSwitch) => {
    uiSwitch.switch = new RemoteSwitch(uiSwitch)
  })
  resolve()
})
