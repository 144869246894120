import Loader from "@/javascript/components/loader"

class CurrencyUpdater {
  constructor(select, fieldsWithCurrency, currencySymbols) {
    this.select = select
    this.currencySymbols = currencySymbols
    this.prepends = Array.from(fieldsWithCurrency).map((field) => {
      const prepend = document.createElement('span')
      prepend.classList.add('prepend')
      prepend.dataset.forInput = field.id

      return field.parentNode.insertBefore(prepend, field)
    })

    this._setPrepend(this.select.selectize.getValue())
    this._bind()
  }

  _bind() {
    this.select.selectize.on('change', (newValue) => {
      this._setPrepend(newValue)
    })
  }

  _setPrepend(currency) {
    const symbol = this.currencySymbols[currency]
    this.prepends.forEach((prepend) => {
      prepend.textContent = symbol
    })
  }
}

Loader.functional((content, resolve) => {
  // TODO: Should some of this logic be in CurrencyUpdater ?
  content.querySelectorAll('[data-fields-with-currency]').forEach((elem) => {
    if(!elem.currencyUpdater) {
      const fieldsWithCurrencyIds = JSON.parse(elem.dataset.fieldsWithCurrency)
      const currencySymbols = JSON.parse(elem.dataset.currencySymbols)
      const fieldsWithCurrency = content.querySelectorAll(fieldsWithCurrencyIds)
      const select = elem.querySelector('select')
      elem.currencyUpdater = new CurrencyUpdater(select, fieldsWithCurrency, currencySymbols)
    }
  })

  resolve()
})
