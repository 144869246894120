import { fadeOut, fadeIn } from "@/javascript/components/animations/fade"
import { slideTransition } from "@/javascript/components/animations/slide"
import wait from "@/javascript/components/tools/wait"
// Displays quickly vanishing feedback message to user at given location.
// Location can be any DOM element

export default function smallTalk (location, text) {
  const message = document.createElement('div')
  message.classList.add('smalltalk')
  message.classList.add('hidden')
  const rect = location.getBoundingClientRect()
  const center = {
    x: (rect.right + rect.left) / 2,
    y: (rect.bottom + rect.top) / 2
  }
  message.style.setProperty('left', center.x + 'px')
  message.style.setProperty('top', center.y + 'px')
  message.style.setProperty('z-index', '100000')
  message.style.setProperty('opacity', 0)
  message.style.setProperty('display', 'none')
  message.innerHTML = text
  document.body.appendChild(message)

  Promise.all([
    slideTransition(message, 200, () => {
      message.classList.remove('hidden')
    }),
    fadeIn(message, 400),
  ])
    .then(() => wait(600))
    .then(() => fadeOut(message, 400))
    .then(() => message.remove())
}
