import { closest } from "@/javascript/components/tools/closest"
import fetch from "@/javascript/components/tools/fetch"
import reportError from "@/javascript/components/errors"
import $ from "jquery"

export default class Suggester {
  constructor (selectize, options = {}) {
    this.selectize = selectize
    this.url = options.suggestionUrl
    this.text = options.text
    this.sources = options.sources
    if(options.getData) {
      this.getData = options.getData
    }
    this._patchSelectize()
    this._bindSuggestions()
  }

  // this function can be overriden to change details sent to suggestion url
  getData () {
    return {
      values: this.selectize.getValue()
    }
  }

  getSuggestions () {
    if(this._suggestions) {
      return Promise.resolve(this._suggestions)
    }

    this.loading = true
    const data = this.getData()
    if(this.sources) {
      data.sources = this.sources
    }

    return fetch(this.url, {
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((data) => {
      this.loading = false
      this._suggestions = data.suggestions
      return this._suggestions
    })
    .catch((error) => {
      reportError(error)
      this.loading = false
      this.resetSuggestions()
    })
  }

  showSuggestions () {
    if(!this.selectize.isOpen) {
      return
    }

    this.selectize.$dropdown_content[0].innerHTML = ''
    Promise.all([
      this.getSuggestions(),
      this._renderWrapper()
    ]).then(([suggestions, wrapper]) => {
      this._renderSuggestions(wrapper, suggestions)
      this._removeLoader(wrapper)
      this.selectize.positionDropdown()
    })
  }

  dismiss () {
    this._dismissed = true
    this.selectize.refreshOptions(false)
    this.selectize.positionDropdown()
  }

  resetSuggestions () {
    this._suggestions = null
  }

  _patchSelectize () {
    const originalRefreshOptions = this.selectize.refreshOptions
    const suggester = this
    this.selectize.refreshOptions = function (triggerDropdown) {
      if(suggester._dismissed || $.trim(this.$control_input.val())) {
        suggester.suggestionsShown = false
        originalRefreshOptions.apply(this, [triggerDropdown])
      } else {
        suggester.showSuggestions()
        if(triggerDropdown && !this.isOpen) {
          this.open()
        }
      }
    }
  }

  _renderWrapper () {
    return new Promise((resolve) => {
      const dropdown = this.selectize.$dropdown_content[0]
      dropdown.innerHTML = ''

      const wrapper = document.createElement('div')
      wrapper.classList.add('suggestions')

      const header =  document.createElement('header')
      header.innerText = this.text.header

      if(this.text.show_all) {
        const dismiss = document.createElement('a')
        dismiss.classList.add('dismiss')
        dismiss.innerText = this.text.show_all
        dismiss.addEventListener('mousedown', (e) => {
          e.preventDefault()
          e.stopPropagation()
          this.dismiss()
        })
        header.appendChild(dismiss)
      }

      wrapper.appendChild(header)
      dropdown.appendChild(wrapper)
      this._showLoader(wrapper)
      this.selectize.positionDropdown()
      resolve(wrapper)
    })
  }

  _renderSuggestions (wrapper, suggestions) {
    if(suggestions.length) {
      suggestions.forEach((suggestion) => {
        const span = this._renderSuggestion(suggestion)
        wrapper.appendChild(span)
      })
    } else {
      if(this.text.not_found) {
        const header = wrapper.querySelector('header')
        header.innerText = this.text.not_found
      } else {
        return this.dismiss()
      }
    }
  }

  _renderSuggestion (suggestion) {
    const span = document.createElement('span')
    span.setAttribute('data-selectable', true)
    span.innerHTML = suggestion[this.selectize.settings.labelField]
    span.suggestion = suggestion
    return span
  }

  _showLoader (wrapper) {
    if(!this.loading) {
      return
    }

    const loader = document.createElement('div')
    loader.classList.add('loader')
    loader.innerText = this.text.loading
    wrapper.appendChild(loader)
  }

  _removeLoader (wrapper) {
    const loader = wrapper.querySelector('.loader')
    if(!loader) {
      return
    }
    loader.remove()
  }

  _bindSuggestions () {
    this.selectize.$dropdown_content[0].addEventListener('mousedown', (e) => {
      const span = closest(e.target, '.suggestions span')
      if(!span) {
        return
      }

      e.preventDefault()
      e.stopPropagation()
      if(!this.selectize.getOption(span.suggestion.value)[0]) {
        this.selectize.addOption(span.suggestion)
      }
      this.selectize.addItem(span.suggestion.value)
      this.selectize.refreshOptions(false)
    })

    this.selectize.on('change', () => {
      this.resetSuggestions()
      this._dismissed = false
      this.selectize.refreshOptions(false)
    })

    this.selectize.on('dropdown_close', () => {
      this._dismissed = false
    })
  }
}
