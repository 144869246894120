import CSRFFetch from "@/javascript/components/tools/fetch"
import reportError from "@/javascript/components/errors"
import Loader from "@/javascript/components/loader"

const bindDismissibaleLinks = (container) => {
  container.querySelectorAll('.js-dismissible-cell[data-dismiss-identifier]').forEach((dismissibleCell) => {
    let dismissButton = dismissibleCell.querySelector('.dismiss-btn')
    if(dismissButton) {
      return
    }

    dismissButton = document.createElement('div')
    dismissButton.classList.add('dismiss-btn')
    dismissibleCell.appendChild(dismissButton)

    dismissButton.addEventListener('click', (e) => {
      dismissibleCell = e.target.closest('.js-dismissible-cell[data-dismiss-identifier]')
      const url = '/dismissible_cells/api/dismiss?id=' + dismissibleCell.dataset.dismissIdentifier
      dismissibleCell.style.maxHeight = dismissibleCell.offsetHeight.toString() + 'px'
      dismissibleCell.classList.add('dismissed')

      CSRFFetch(url, {
        method: 'PATCH',
        headers: {'content-type': 'application/json'}
      })
        .then((response) => {
          response.json()
        })
        .catch((error) => {
          reportError(error)
        })
    })
  })
}

Loader.functional((container, resolve) => {
  bindDismissibaleLinks(container)
  resolve()
})
