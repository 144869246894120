import Loader from "@/javascript/components/loader"
import Selectize from "@/javascript/components/selects"

class JobCategoriesSelect {
  constructor(select) {
    const defaultOptions = {
      closeAfterSelect: true,
      preload: true,
      search: true
    }

    this.options = Object.assign({}, defaultOptions, select.dataset);
    this.select = select
    this._setupSelect()
  }

  _countSelectedCategories () {
    return this._selectedCategories().length
  }

  _maxItems () {
    if (!this.options.maxJobCategories) {
      return
    }

    return this._countSelectedCategories() > this.options.maxJobCategories ? this._countSelectedCategories() : this.options.maxJobCategories
  }

  _selectedCategories () {
    return Array.from(this.select.querySelectorAll('option[selected="selected"]')).map((option) => {
      return option.value
    })
  }

  _setupSelect () {
    Selectize(this.select, {
      maxItems: () => {this._maxItems()},
      closeAfterSelect: JSON.parse(this.options.closeAfterSelect),
      preload: JSON.parse(this.options.preload),
      search: JSON.parse(this.options.search),
      render: {
        option: (data, escape) => {
          let descriptionTag = ''
          const description = data.data?.job_category_description

          if (description) {
            descriptionTag = `<span class="job-categories-description">${escape(description)}</span>`
          }
          return `<div class="job-category-option">
            <span class="job-category-name">${escape(data.text)}</span>
            ${descriptionTag}
          </div>`
        }
      }
    })
    .then((selectize) => {
      selectize.on('change', () => {
        this._updateMaxItems()
        this._fireEvent('job_categories:refresh', { categories: this._selectedCategories() })
      })
    })
  }

  _updateMaxItems () {
    this.select.selectize.setMaxItems(this._maxItems())
    if(this._countSelectedCategories() === this._maxItems()) {
      this.select.selectize.close()
    }
  }

  _fireEvent (name, options = {}) {
    const event = new CustomEvent(name, {detail: options})
    document.dispatchEvent(event)
  }
}

Loader.functional((content, resolve) => {
  const jobCategorySelect = content.querySelector('.js-job-categories-selects select')

  if(!jobCategorySelect) {
    resolve()
    return
  }

  if(!jobCategorySelect.control) {
    jobCategorySelect.control = new JobCategoriesSelect(jobCategorySelect)
  }

  resolve()
})
