import { closest } from "@/javascript/components/tools/closest"

const assignableAttributes = [
  'public_id',
  'version',
  'format',
  'resource_type',
  'width',
  'height'
]

class CloudinaryUploader {
  constructor(elem, cloudName, options = {} ) {
    this.elem = elem
    this.options = {
      cloud_name: cloudName,
      theme: 'white',
      sources: ['local', 'url', 'camera'],
      widget_host: 'https://upload-widget.cloudinary.com/widget/index.html'
    }

    Object.entries(options).forEach(entry => {
      const [key, value] = entry;
      this.options[key] = value
    });
  }

  upload() {
    let promise = new Promise((resolve, reject) => {
      cloudinary.openUploadWidget(this.options, (error, result) => {
        if(error) {
          return reject(error)
        }
        if(result.event === "success") {
          return resolve(result.info)
        }
      })
    })
    promise = this._applyDefaultHandlers(promise)

    if(this.options.onSuccess) {
      return promise.then(() => this.options.onSuccess)
    }
  }

  cleanErrors() {
    this.elem.classList.remove('with-error')
    const errorsContainer = this.elem.querySelector('.errors')
    errorsContainer.innerHTML = ''
    errorsContainer.classList.add('hidden')
  }

  showRemoveButton() {
    this.elem.querySelector('.remove-button').classList.remove('hidden')
  }

  hideRemoveButton() {
    this.elem.querySelector('.remove-button').classList.add('hidden')
  }

  clear() {
    this.elem.querySelector('input[type="hidden"]').value = null
    this.elem.querySelector('img').src = this.options.default || '//:0'
    this.hideRemoveButton()
    this.elem.dispatchEvent(new CustomEvent('imageRemoved', { bubbles: true }))
  }

  _applyDefaultHandlers(promise) {
    return promise
      .then((result) => {
        const input = this.elem.querySelector('input[type="hidden"]')
        const data = {}
        assignableAttributes.forEach((key) => {
          data[key] = result[key]
        })
        input.value = JSON.stringify(data)
        return result
      })
      .then((result) => {
        const image = this.elem.querySelector('img')
        image.src = result.secure_url
        return result
      })
      .then((result) => {
        this.cleanErrors()
        this.showRemoveButton()
        this.elem.dispatchEvent(new CustomEvent('imageUploaded', { bubbles: true }))
        return result
      })
      .catch((error) => {
        if(error.kind !== 'error') {
          return
        }
        this.elem.classList.add('with-error')
        const errorsContainer = this.elem.querySelector('.errors')
        errorsContainer.innerHTML = error.message
        errorsContainer.classList.remove('hidden')
      })
  }
}

document.addEventListener('click', (e) => {
  const target = e.target || window.target
  const elem = closest(target, '[data-upload]')
  if(!elem) {
    return
  }

  if(!elem.uploader) {
    const options = JSON.parse(elem.dataset.upload || '{}')
    const cloudName = elem.dataset.cloudName
    elem.uploader ||= new CloudinaryUploader(elem, cloudName, options)
  }

  if(closest(target, '[data-upload] a.upload-button')) {
    return elem.uploader.upload()
  }

  if(closest(target, '[data-upload] a.remove-button')) {
    return elem.uploader.clear()
  }
})
