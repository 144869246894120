import Loader from "@/javascript/components/loader"
import Selectize from "@/javascript/components/selects"

class JobCategoriesOnboardingSelect {
  constructor(container) {
    this.container = container
    this.addButton = this.container.querySelector(".add-job-category")
    this._storeSelectedCategories()
    this._setupSelects()
    this._refreshCategoriesOptions()
    this._bindAddButton()
    this._updateForm()
  }

  getSelectedCategories () {
    return this._getJobCategoryContainers().map((container) => {
      return container.querySelector('select option:checked').value
    })
  }

  _setupSelects () {
    this._selects().forEach((select) => this._setupSelect(select))
  }

  _setupSelect(select) {
    Selectize(select, {
      maxItems: 1,
      closeAfterSelect: true,
      preload: true,
      search: true,
      render: {
        option: (data, escape) => {
          let description = escape(data.data.job_category_description)
          if(description === 'null') {
            description = ''
          }
          return `<div class="job-category-option">
            <span class="job-category-name">${escape(data.text)}</span>
            <span class="job-categories-description">${description}</span>
            </div>`
        }
      }
    })
    .then((selectize) => {
      selectize.on('change', () => {
        this._storeSelectedCategories()
        this._updateForm()
        this._fireEvent('job_categories:refresh', {categories: this.selectedCategories})
        if (this._getChosenJobCategories().length >= 1 || this._getChosenJobCategories().length <= 2) {
          this.addButton.classList.add('open')
        }
      })
    })
  }

  _refreshCategoriesOptions() {
    document.querySelectorAll('.job-categories-select').forEach((item) => {
      if(!item.selectize) {
        return
      }
      item.selectize.refreshOptions(false)
    })
  }

  _synchronizeSelects () {
    document.querySelectorAll('.job-category-option').forEach((option) => {
      if(this.selectedCategories.includes(option.dataset.value)) {
        option.classList.add('hide')
      } else {
        option.classList.remove('hide')
      }
    })
  }

  _storeSelectedCategories() {
    this.selectedCategories = this.getSelectedCategories()
  }

  _selects () {
    return this.container.querySelectorAll('select')
  }

  _fireEvent (name, options = {}) {
    const event = new CustomEvent(name, {detail: options})
    document.dispatchEvent(event)
  }

  _bindAddButton() {
    this.addButton.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      this._addSelect()

      if(this._getJobCategoryContainers().length > 2) {
        this.addButton.remove()
      }
    })
  }

  _addSelect () {
    const nextSelect = this.container.querySelectorAll('.job-categories-wrapper.hide')[0]
    if(typeof(nextSelect) != 'undefined') {
      nextSelect.classList.remove('hide')
      nextSelect.classList.add('show')
    }
    const chosenJobCategoriesCount = this._getChosenJobCategories().length
    if(chosenJobCategoriesCount >= 3) {
      this.addButton.classList.remove('open')
    }
  }

  _getChosenJobCategories () {
    return this.container.querySelectorAll('.job-categories-wrapper.show')
  }

  _populateRealSelect() {
    const hiddenInput = document.getElementById('job-category-ids').querySelector('select').selectize
    hiddenInput.clear()
    this.container.querySelectorAll('.job-categories-select').forEach((item) => {
      if(!item.selectize) {
        return
      }
      hiddenInput.addItem(`${item.selectize.items[0]}`)
    })
  }

  _updateForm () {
    // onItemRemove callback on selectize is not created when item is replaced, resulting in some brute solutions here
    this._synchronizeSelects()
    this._populateRealSelect()
  }

  _getJobCategoryContainers () {
    const containers = Array.from(this.container.querySelectorAll(".desired-job-categories"))
    return containers.filter((container) => {
      return !container.querySelector('.job-categories-wrapper').classList.contains('hide')
    })
  }
}

Loader.functional((content, resolve) => {
  const jobCategorySelectsContainer = content.querySelector('.js-job-categories-onboarding-selects')

  if(!jobCategorySelectsContainer) {
    resolve()
    return
  }

  if(!jobCategorySelectsContainer.control) {
    jobCategorySelectsContainer.control = new JobCategoriesOnboardingSelect(jobCategorySelectsContainer)
  }

  resolve()
})
