import { initModalLink } from "@/javascript/components/modal"
import announce from "@/javascript/components/tools/announce"
import { closest } from "@/javascript/components/tools/closest"
import { replaceWith } from "@/javascript/components/tools/replace"
import Loader from "@/javascript/components/loader"
import $ from "jquery"

const bindHandlers = (form, modal) => {
  $(form).on('ajax:success', (event) => {
    const [_data, _status, xhr] = event.detail

    announce(
      modal.editable, replaceAndClose,
      { name: 'editableReplace', content: xhr.response, target: modal.editable, modal: modal }
    )
  })

  $(form).on('ajax:error', (event) => {
    const [_data, _status, xhr] = event.detail

    replaceWith(form, xhr.responseText, (form) => {
      bindHandlers(form, modal, modal.editable)
    })
    .then((newForm) => {
      newForm.dispatchEvent(new CustomEvent('formReloaded', {bubbles: true}))
    })
  })
}

const prepareEditable = (editableButton) => {
  const modal = initModalLink(editableButton)
  modal.editable = closest(editableButton, '.editable')
  modal.on('load', (container) => {
    bindHandlers(container.querySelector('form'), modal)
  })
}

const replaceAndClose = (detail) => {
  return replaceWith(detail.target, detail.content)
    .then(() => detail.modal.close())
}

Loader.functional((content, resolve) => {
  content.querySelectorAll('[data-editable]').forEach((elem) => {
    prepareEditable(elem)
  })

  resolve()
})
