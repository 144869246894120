import "./optimized_resize"
import Loader from "@/javascript/components/loader"

class AreaResizer {
  static all = []

  constructor (area) {
    this.area = area
    AreaResizer.all.push(this)
    this.area.style.setProperty('overflow-y', 'hidden')
    this.area.addEventListener('input', () => this.resize())
  }

  resize () {
    this.area.style.setProperty('height', 'auto')
    this.area.style.setProperty('height', this.area.scrollHeight + 'px')
  }
}

Loader.functional((container, resolve) => {
  container.querySelectorAll('textarea[data-autoresize]').forEach((area) => {
    area.resizer = new AreaResizer(area)
  })
  resolve()
})

window.addEventListener('optimizedResize', () => {
  AreaResizer.all.forEach((resizer) => {
    resizer.resize()
  })
})
